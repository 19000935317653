<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <InformationAll
          v-if="isRendered('general')"
          id="general"
          refs="information"
          :type="type"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='general'}"
        />
        <TableLaborMarketInfor
          v-if="isRendered('worker')"
          id="worker"
          class="tab-pane"
          :collectReportId="collectReportId"
          :class="{active:activeTab==='worker'}"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    InformationAll: () => import('./InformationAll.vue'),
    TableLaborMarketInfor: () => import('./TableLaborMarketInfor.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      collectReportId: null,
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'worker',
          title: 'Thông tin báo cáo',
          icon: 'ActivityIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataGeneral: {
        code: '',
        dateReport: null,
        year: 0,
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataGeneral = {
          code: this.dataDetail.code,
          dateReport: this.dataDetail.dateReport,
          year: this.dataDetail.year,
        }
        this.collectReportId = this.dataDetail.id
      }
    },

    type() {
      if (this.type === 'edit') {
        this.listTabs[1].isDisabled = false
      }
      if (this.type === 'add') {
        this.listTabs[1].isDisabled = true
      }
    },
    activeTab(val) {
      if (val === null) {
        this.activeTab = 'general'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    accept(bvModalEvt) {
      const model = {
        ...this.dataGeneral,
        year: Number(this.dataGeneral.year),
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', model)
        }
      })
    },

    resetData() {
      this.activeTab = null
      this.dataGeneral = {
        code: '',
        dateReport: null,
        year: 0,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
